<template>
  <b-card-code>
    <div class="d-flex justify-content-end">
      <b-button @click="goBack()" size="sm" variant="primary">Back</b-button>
    </div>
    <validation-observer ref="AddForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="add">
        <b-form-group label="Template Name" label-for="Template Name">
          <template #label>
            <span>Template Name</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="template_name"
            :rules="{
              required: true,
              regex: /^[a-zA-Z0-9\s\.\-\_\(\)\ [\]\ {\} \|\/ ]+$/,
            }"
          >
            <b-form-input
              id="template_name"
              v-model="form.template_name"
              :state="errors.length > 0 ? false : null"
              name="template_name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!--Upload File -->
        <b-form-group label="Upload File" label-for="File">
          <template #label>
            <span>Upload File</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="file"
            rules="required|ext:doc,docx"
          >
            <b-form-file
              v-model="doc_file"
              :state="Boolean(doc_file)"
              ref="file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              v-on:change="handleFileUpload()"
            ></b-form-file>
            <div class="mt-1">
              Selected file: {{ doc_file ? doc_file.name : "" }}
            </div>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- customer publish -->
        <b-form-group>
          <b-form-checkbox
            id="checkbox-10"
            name="checkbox-10"
            :checked="publishCustomer"
            v-model="publishCustomer"
          >
            Publish to Customer
          </b-form-checkbox>
        </b-form-group>

        <!-- Row Loop -->
        <!-- Row Loop -->
        <div class="mt-3">
          <h4>Add Variables</h4>
          <p class="mt-1">
            The variables will be replaced with the actual values you provide
            while generating the report. Make sure the variables you add here
            exist in the template you upload. For ex. If you add `report_name`
            here, your template should contain `{{ report_name }}`
          </p>

          <b-row
            v-for="(item, index) in items"
            :id="item.id"
            :key="item.id"
            ref="row"
          >
            <!-- Item Name -->
            <b-col md="4">
              <b-form-group
                label="Variable to be replaced"
                label-for="variable"
              >
                <template #label>
                  <span>Variable to be replaced</span>
                  <span
                    class="text-danger font-weight-bold h5"
                    style="margin-left: 3px"
                    >*</span
                  >
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="variable"
                  :rules="{ required: true, regex: /^[a-zA-Z0-9\s\_]+$/ }"
                >
                  <b-form-input
                    :id="`variable_${item.id}`"
                    v-model="item.variable"
                    type="text"
                    placeholder="Ex. report_title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Item Name -->
            <b-col md="4">
              <b-form-group
                label="A label for the variable"
                label-for="Variable Label"
              >
                <template #label>
                  <span>A label for the variable</span>
                  <span
                    class="text-danger font-weight-bold h5"
                    style="margin-left: 3px"
                    >*</span
                  >
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="variable_label"
                  :rules="{ required: true, regex: /^[a-zA-Z0-9\s]+$/ }"
                >
                  <b-form-input
                    id="variable_label"
                    v-model="item.variable_label"
                    type="text"
                    placeholder="Ex. Report Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="2" md="3" class="mb-50">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
              >
                <feather-icon icon="XIcon" class="mr-25" />
                <span>Delete</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
          </b-row>
          <b-button @click="repeateAgain" variant="secondary" size="sm">
            Add New Variable
          </b-button>
        </div>
        <b-button
          type="submit"
          variant="primary"
          :disabled="invalid || disable"
          class="float-right"
          size="sm"
        >
          <span v-if="isLoading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Save Template</span>
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      report_name: "{{report_name}}",
      is_staff: this.$store.state.app.user.is_staff,
      doc_file: null,
      form: {
        template_name: "",
        template_meta_fields: [],
      },
      items: [
        {
          id: 1,
          variable: "",
          variable_label: "",
        },
      ],
      nextItemId: 1,
      publishCustomer: false,
      disable: false,
      isLoading: false,
    };
  },
  created: function () {
    //  this.load(); --> not calling this due page load issues
  },
  methods: {
    load: function () {},

    add: function () {
      this.$refs.AddForm.validate().then((success) => {
        this.disable = true;
        this.isLoading = true;

        if (success) {
          //call to axios
          //   Correct the items array
          let i = 0;
          var self = this;
          this.items.forEach(function (item, index) {
            i = i + 1;
            self.items[index].id = i;
          });
          self.form.template_meta_fields = JSON.stringify(self.items);
          /////////
          // Prepare form data
          let formData = new FormData();
          formData.append("doc_file", this.doc_file);
          formData.append("is_available_to_customer", this.publishCustomer);
          formData.append("template_name", self.form.template_name);
          formData.append(
            "template_meta_fields",
            self.form.template_meta_fields
          );
          ////
          const options = {
            method: "POST",
            headers: { "content-type": "application/json" },
            data: formData,
            url: process.env.VUE_APP_BASEURL + "report/report-template/",
          };
          this.$http(options).then((res) => {
            console.log(res);
            if (res.data.template_id) {
              this.$router.push({ name: "List Templates" });
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Successfully added! ",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              // }
            }

            this.disable = false;
            this.isLoading = false;
          });
        }
      });
    },
    repeateAgain() {
      this.items.push({
        id: (this.nextItemId = this.nextItemId + 1),
        variable: "",
        variable_label: "",
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
      //   this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    handleFileUpload() {
      this.doc_file = this.$refs.file.files[0];
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>